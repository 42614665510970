import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzIconService} from 'ng-zorro-antd/icon';

import {AuthenticationService} from './auth/_services/authentication.service';
import {Common} from './_common';

import {VersionCheckService} from './_services';
import {environment} from '../environments/environment';

@Component({selector: 'app-root', templateUrl: 'app.component.html'})

export class AppComponent implements OnInit, OnDestroy {
  REPLACER_DEPLOY_URL = 'https://qa-ctrl.nexxen.com/resources/20241114093200';
  isRouteGuarded = false;
  googleTagUrl = `https://www.googletagmanager.com/ns.html?id=${environment.googleTagContainerId}`;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private notification: NzNotificationService,
    public titleService: Title,
    private common: Common,
    public nzIconService: NzIconService,
    private vcs: VersionCheckService,
  ) {
    if (!this.REPLACER_DEPLOY_URL.includes('***')) {
      this.nzIconService.changeAssetsSource(this.REPLACER_DEPLOY_URL);
    }
  }

  ngOnInit(): void {
    if (!location.origin.includes('localhost')) {
      const versionCheckURL = `${location.origin}/version.json`;
      this.vcs.initVersionCheck(versionCheckURL, 180000); // version check every 3 minutes
    }

    // Update page title from router data
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
      // checks if any of the path segments have a canActivate guard, which means the route
      // is guarded and cannot be accessed without user credentials
      tap(route => {
        this.isRouteGuarded = !!route.pathFromRoot.filter(a => _.get(a, 'routeConfig.canActivate')).length;
      }),
      mergeMap((route) => route.data),
      filter((d: Data) => !_.isEmpty(d))
    ).subscribe((event) => {
      this.titleService.setTitle(event['title'] + ' - Nexxen');
    });
  }

  ngOnDestroy(): void {
    this.authenticationService.unsubscribe$.next();
    this.authenticationService.unsubscribe$.complete();
  }

}
